body {
    min-height: 100vh;
    background-image: url("./media/logo/smallLogoWhiteFooter.svg");
    background-repeat: initial;
    background-size: 40px;
}

footer {
    background-image: url("./media/logo/smallLogoFooter.svg");
    background-repeat: initial;
    background-size: 150px;
    background-color: #c0ab8c;
}

button:focus{
    outline: none;
}


p, h1, h2, h3, h4, h5, h6, strong, em, del, ul, ol, li {
    color: inherit;
    font-weight: inherit;
}

p {
    font-size: 17px;
}

h1 {
    font-size: 60px;
    line-height: 60px;
    font-family: "Playfair+Display", serif;
}

h2 {
    font-size: 27px;
}

h3 {
    font-size: 25px;
}

h4 {
    font-size: 23px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 17px;
}

@media (max-width: 600px) {
    p {
        font-size: 16px;
    }

    h1 {
        font-size: 45px;
        line-height: 45px;
        font-family: "Playfair+Display", serif;
    }

    h2 {
        font-size: 25px;
    }

    h3 {
        font-size: 23px;
    }

    h4 {
        font-size: 21px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 15px;
    }
}

@media (max-width: 400px) {
    p {
        font-size: 15px;
    }

    h1 {
        font-size: 30px;
        line-height: 30px;
        font-family: "Playfair+Display", serif;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 15px;
    }

    h6 {
        font-size: 12px;
    }
}

.cookies p {
    font-size: 15px;
}

.cookies h1, .cookies h2, .cookies h3, .cookies h4, .cookies h5, .cookies h6 {
    font-size: 20px;
}

.textWhite > * {
    color: #ffffff !important;
}

.margin-0 > * {
    margin: 0 !important;
}

.margin-5 > * {
    margin: 5px !important;
}

.titleMargin-0 > h1, h2, h3, h4, h5, h6 {
    margin: 0 !important;
}

.extraMargin-p > p {
    margin-block-start: 2em;
    margin-block-end: 2em;
}

.titleContrast > p, .titleContrast > h2, .titleContrast > h3, .titleContrast > h4, .titleContrast > h5, .titleContrast > h6, .titleContrast > strong, .titleContrast > em, .titleContrast > del, .titleContrast > ul, .titleContrast > ol, .titleContrast > li {
    color: #899194;
}

.grayColor{
    color: #899194
}

.p-gray > p{
    color: #899194
}

.linkContrast > p > a {
    text-decoration: underline !important;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

.dec a {
    text-decoration: none;
    color: inherit;
}

.dec a:hover {
    text-decoration: underline;
    color: inherit;
}

.aws-btn {
    --slider-height-percentage: 60%;
    --slider-transition-duration: 525ms;
    --organic-arrow-thickness: 1px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 23px;
    --organic-arrow-color: #d6b18f;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #fcd0a8;
    --control-bullet-active-color: #d6b18f;
    --loader-bar-color: #fff0e9;
    --loader-bar-height: 6px;
}

